import React from "react"
import { graphql } from "gatsby"
import Wrapper from "../wrappers"
import styled from "styled-components"
import Gif from "../images/call.gif"
import Layout from "../layouts/default"

const Container = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  margin: 50px auto;
  min-height: 60vh;
  text-align: center;
`

const Heading = styled.div`
  font-size: 35px;
  font-weight: bold;
  color: ${props => props.theme.primaryColor};
  text-transform: uppercase;
  margin: 20px auto;
  @media only screen and (max-width: 786px) {
    font-size: 25px;
  }
`

const Image = styled.img`
  border-radius: 100%;
  width: 300px;
  height: 300px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  @media only screen and (max-width: 786px) {
    width: 150px;
    height: 150px;
  }
`

export default ({ data }) => {
  return (
    <Layout>
      <Wrapper>
        <Container>
          <Image src={Gif} alt='prollydrunk.today'/>
          <Heading>Contact Me</Heading>
          <div>Feel Free to Email me here.</div>
          <a href="mailto:ashishajimal14@gmail.com">ashishajimal14@gmail.com</a>
        </Container>
      </Wrapper>
    </Layout>
  )
}
